import React, { useState } from "react";
import AsyncSelect from 'react-select/async';
import apiEndpoint from '../../utils/apiEndpoint';

function SearchInput({ path, queryParameter, selectedOptions, setSelectedOptions }) {
  // routeDetails: obj: {path: '/diagnosis', queryParameter: 'text'}
  // path => allowed values: '/diagnosis', '/drugs', '/tests'

  const handleChange = (userSelectedOptions) => {
    setSelectedOptions(userSelectedOptions.map(option => option.value));
  };
  
  async function searchOptions(inputValue) {
    const url = `${apiEndpoint}${path}?${queryParameter}=${inputValue}`;
    const data = await fetch(url).then(res => res.json());
    // do error validations
    const options = [{label: inputValue, value: inputValue}, ...data.map(name => ({label: name, value: name}))];
    return Promise.resolve(options);
  }

  return (
    <AsyncSelect
    isMulti
    cacheOptions
    loadOptions={searchOptions}
    onChange={handleChange}
    debounce={0}
  />
  );
}

export default SearchInput;