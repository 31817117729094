

const env = process.env.ENV;
let endpoint = '';
if (env === 'development') {
    endpoint = 'http://localhost:4000';
} else if (env === 'production') {
    endpoint = 'https://azeemhcp1.alwaysdata.net';
}

export default endpoint;