import React from "react";
import SearchInput from '../SearchInput';
import { SelectedDiagnosisContext } from "../App";

function DiagnosisSearch() {
  const { selectedDiagnosis, setSelectedDiagnosis } = React.useContext(SelectedDiagnosisContext);
  return (
    <SearchInput
      path="/diagnosis"
      queryParameter="text"
      selectedOptions={selectedDiagnosis}
      setSelectedOptions={setSelectedDiagnosis}
    />
  );
}

export default DiagnosisSearch;