import React from 'react';
import apiEndpoint from '../../utils/apiEndpoint';
import DiagnosisSearch from '../DiagnosisSearch';
import DrugsSearch from '../DrugsSearch';
import TestsSearch from '../TestsSearch';

export const SelectedDrugsContext = React.createContext();
export const SelectedDiagnosisContext = React.createContext();
export const SelectedTestsContext = React.createContext();

function App() {

  const [selectedDrugs, setSelectedDrugs] = React.useState([]);
  const [selectedDiagnosis, setSelectedDiagnosis] = React.useState([]);
  const [selectedTests, setSelectedTests] = React.useState([]);

  console.log({ selectedDiagnosis, selectedDrugs, selectedTests });

  return (
    <SelectedDrugsContext.Provider value={{selectedDrugs, setSelectedDrugs}}>
    <SelectedDiagnosisContext.Provider value={{selectedDiagnosis, setSelectedDiagnosis}}>
    <SelectedTestsContext.Provider value={{selectedTests, setSelectedTests}}>
    <h1>Greetings this is search UI Live Demo for</h1>
    <a href={`${apiEndpoint}/documentation`}><h2>API Documentation</h2></a>
    <nav>
      <ul>
        <li>Diagnosis Searching  <DiagnosisSearch /></li>
        <br/>
        <li>Drugs(Medicines) Searching  <DrugsSearch /></li>
        <br/>
        <li>Lab Tests Searching   <TestsSearch /></li>
      </ul>
    </nav>
    <br/>
    
    
    
  </SelectedTestsContext.Provider>
  </SelectedDiagnosisContext.Provider>
  </SelectedDrugsContext.Provider>
);
}

export default App;
