import React from "react";
import SearchInput from '../SearchInput';
import { SelectedDrugsContext } from '../App';

function DrugsSearch() {
  const { selectedDrugs, setSelectedDrugs } = React.useContext(SelectedDrugsContext);
  return (
    <SearchInput
      path="/drugs"
      queryParameter="text"
      selectedOptions={selectedDrugs}
      setSelectedOptions={setSelectedDrugs}
    />
  );
}

export default DrugsSearch;