import React from "react";
import SearchInput from '../SearchInput';
import { SelectedTestsContext } from "../App";

function TestsSearch() {
  const { selectedTests, setSelectedTests } = React.useContext(SelectedTestsContext);
  return (
    <SearchInput
      path="/tests"
      queryParameter="text"
      selectedOptions={selectedTests}
      setSelectedOptions={setSelectedTests}
    />
  );
}

export default TestsSearch;